import React from 'react';
import PhoneIcon from '../../svg/phone.svg'

export default ({number,className,textPhoneColor,textColor})=>{
  textPhoneColor = textPhoneColor || "d-text-green";
  textColor = textColor || "text-gray-700"
  return (
    <a href={"tel:" + number} className={textColor+ " " + className} style={{ minWidth: "150px" }}>
      <div className="svg-container inline-block mr-2">
        <PhoneIcon />
      </div>
      {number}
    </a>
  );
}
  