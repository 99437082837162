import React, { useEffect, useState } from 'react'
import Header from './header'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import favicon16 from "../images/favicon-16x16.png";
import favicon32 from "../images/favicon-32x32.png";
import favicon64 from "../images/favicon.ico";

import '../sass/layout.css'
import '@fortawesome/fontawesome-free/js/all'

const Layout = ({ children }) => {
  const [disableIcons, setDisableIcons] = useState(false);
  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const noIcons = urlParams.get('no-icons');
    setDisableIcons(noIcons)
  },[]);
  return(
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            link={[
              { rel: "icon", type: "image/png", sizes: "16x16", href: `${favicon16}` },
              { rel: "icon", type: "image/png", sizes: "32x32", href: `${favicon32}` },
              { rel: "shortcut icon", type: "image/png", href: `${favicon64}` },
            ]}
            meta={[
              { name: 'description', content: 'Sample' },
              { name: 'keywords', content: 'sample, something' },
            ]}
          >
            <html lang="es" class={ disableIcons && "no-icon"} />
          </Helmet>
          <Header siteTit
          le={data.site.siteMetadata.title} />
          <div className="">{children}</div>
          
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
