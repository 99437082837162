import React from 'react';
import { Link } from 'gatsby';

export default (props)=>{
  return(
    <ul className="sm:flex flex-col submenu block">
      <li><Link to="/sucursales">Sucursales</Link></li>
      <li className="hidden md:block"><Link to="/tuxtla-gutierrez"> Tuxtla Gutiérrez </Link></li>
      <li className="hidden md:block"><Link to="/foraneas"> Foráneas </Link> </li>
    </ul>
  )
}