import React, { Fragment, useState } from 'react'
// import { Link } from 'gatsby'
import logo from '../images/logo.png';
import imech from '../images/imech.png';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import PhoneNumber from './PhoneNumber';
import SucursalesDropDown from './SucursalesDropDown';
import ClockIcon from '../../svg/clock.svg';
import EnglishIcon from '../../svg/united-states.svg'
import FacebookIcon from '../../svg/facebook.svg'
import TwitterIcon from '../../svg/twitter.svg'

const Header = ({ siteTitle }) => {
  const [menuState, setMenuState] = useState(false);
  return (<Fragment >
  {/* <div className="d-gradient-blue h-8"></div> */}
  <header className="bg-white md:pb-4 lg:py-4">
    <div className="md:max-w-6xl mx-auto ">
      <span className="block order-first lg:order-none text-center w-full lg:w-auto bg-red-gradient text-white font-bold text-lg uppercase shadow-lg lg:ml-4 p-2 lg:rounded-lg">
        <ClockIcon style={{height:"1.5em",marginTop:"-4px"}} /> Urgencias 24hrs
        <div className="border-gray-400 sm:ml-4 ml-2 sm:pl-4 pl-2 border-l-2 border-solid no-underline text-lg inline-block items-center">
          <PhoneNumber textColor="text-white" textPhoneColor="text-white" number="9616114773" />
        </div>
      </span>
      <div className="flex flex-col sm:flex-row items-center flex-wrap justify-center ">
          <h1 className="sm:w-auto w-full flex mt-2 sm:mt-0 justify-center items-center md:justify-start ">
            <img src={logo} alt="Logo de Diagnosur"  className="flex-shrink-0" style={{width:"300px"}} />
          </h1>
          <div className="flex-1 w-full sm:w-auto">
            <div className="gcse-search" ></div>
          </div>
        <img src={imech} alt="Logo IMECH" style={{ width: "150px" }} className="inline-block mr-2 md:mt-0 sm:px-4 sm:ml-6 md:ml-0 " />
      </div>
      <div className="flex flex-col sm:flex-row items-center mt-6 flex-wrap sm:items-start md:items-center sm:justify-center ">
        <nav className=" font-bold flex-1 text-center main-navigation d-text-blue">
          <ul className={(menuState ? 'open' : null) + " menu"}>
            <li onClick={ ()=> setMenuState(!menuState)}>Menú <i className="fa fa-bars"></i> </li>
            <li ><Link to="/">Inicio</Link></li>
            <li ><Link to="/servicios">Estudios y Pruebas</Link></li>
            <SucursalesDropDown />
            <li><a href="http://www.diagnosur.com.mx/wsdiagnosur/frontend/web/index.php">Resultados</a></li>
            
            <li ><Link to="/acercade">Acerca de Nosotros</Link></li>
            <li className="md:hidden">
              <a href="http://translate.google.com/translate?js=n&sl=es&tl=en&u=https://inspiring-fermi-7a72cf.netlify.com/">
                  <EnglishIcon className="mr-2" /> English version
              </a>
            </li>
          </ul>
        </nav>
        <div className="w-full hidden md:block sm:w-auto flex-shrink-0 text-center sm:text-left sm:ml-4 md:ml-0 my-4 sm:my-0">
            <a className="text-2xl mr-4" href="http://translate.google.com/translate?js=n&sl=es&tl=en&u=https://inspiring-fermi-7a72cf.netlify.com/"><EnglishIcon style={{ height: "1.2em",marginTop:"3px" }}  /></a>
          <a href="https://twitter.com/diagnosur" className="inline-block mr-4">
            <TwitterIcon style={{height:"1.5em"}} />
          </a>
          <a href="https://web.facebook.com/diagnosur/">
            <FacebookIcon style={{ height: "1.5em" }}  />
          </a>
        </div>
      </div>
    </div>
    
  </header>
  </Fragment>)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
