import React from 'react';
import Aside from './Aside';
export default (props)=>{
  const hideAside = props.hideAside || false;
  return (<div className={"mx-auto max-w-6xl " + props.className}>
    <div className="flex flex-wrap">

      <div className="sm:flex-1 w-full px-4 sm:px-0 max-w-full">
        {props.children}
      </div>
      {!hideAside && <div className="ml-16" style={{ width: "200px" }} > <Aside /> </div>}
    </div>
  </div>);
}