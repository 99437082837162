import React from 'react';
import PhoneNumber from './PhoneNumber';
import { Link } from 'gatsby';

export default (props) => {
  const phones = [
    '961 61 147 73',
    '961 61 468 20',
    '961 61 253 44'

  ];
  return(
    <div>
      <aside className="mt-4">
        <p className="font-bold">Dudas y Contacto: </p>
        <nav>
          {
            phones.map((number, index) => (
              <PhoneNumber number={number} key={index} className="block mt-2" />
            ))
          }
        </nav>
      </aside>
      <aside className="bg-gray-100 p-4 rounded mt-4">
        <p className="font-bold">Encuentra tu sucursal </p>
        <p className="text-sm text-gray-700">Contamos con decenas de sucursales distribuidas por todo el estado</p>
        <Link to="/sucursales" className="mt-4 text-blue-700 underline inline-block font-bold"> Ver sucursales </Link>
      </aside>
    </div>
  )
}