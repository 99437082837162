import React from 'react';
import { Link } from 'gatsby';
const items = [
  {
    text: 'Preguntas frecuentes',
    link: '/preguntas-frecuentes'
  },
  {
    text: 'Servicios',
    link: '/servicios'
  },
  {
    text: 'Uso de sitio web',
    link:'/terminos-uso'
  },
  {
    text: 'Aviso de Privacidad',
    link:'/aviso-privacidad'
  },
  {
    text: 'Términos y Políticas',
    link:'/terminos-politicas'
  }
];
export default ()=>{
  return(
    <nav className="flex justify-between">
      {
        items.map ((el,i)=>{
          return <Link to={el.link} key={i}>{el.text}</Link>
        })
      }
    </nav>
  );
}